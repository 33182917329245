import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"
import Card from "../../components/cards/card.js"
import ImageCard from "../../components/cards/imageCard.js"

import EvansCountyBOE from "../../images/evans-county-boe.jpg"
import PCA from "../../images/pca.jpeg"
const EducationPage = props => (
  <CommunityLayout pageTitle="Education">
    <div>
      <ImageCard src={EvansCountyBOE} alt="Evans County Board of Education">
        <h2>Evans County School District</h2>
        <p>
          The Evans County School District is a school district based in Evans County, Georgia, United States. The superintendent is Dr. Marty Waters. All of the schools have been accredited by the Southern 
          Association of Colleges and Schools as well as the Georgia Accrediting Commission.{" "}
        </p>
        <a href="http://www.evans.k12.ga.us" traget="_blank">
          www.evans.k12.ga.us
        </a>
      </ImageCard>
      <ImageCard src={PCA} alt="Pinewood Christian Academy">
        <h2>Pinewood Christian Academy</h2>
        <p>
          Pinewood Christian Academy offers classes in Pre-Kindergarten through
          12th grade. PCA’s curriculum is broadly based with a dual emphasis
          upon preparing students for the rigors of post- secondary education as
          well as instilling Christian values.{" "}
        </p>
        <a href="http://www.gopca.org" targe="_blank">
          www.gopca.org
        </a>
      </ImageCard>
      <Card>
        <h2>The Evans-Tattnall County Christian Home Educators</h2>
        <p>
          The Evans-Tattnall County Christian Home Educators Association offers
          a homeschool experience complete with enrichment classes.{" "}
        </p>
      </Card>
      <Card>
        <h2>Higher Education</h2>
        <p>
          Higher education options for Evans County graduates within a 30- mile
          radius include:
        </p>
        <ul css={{marginLeft: '2.5rem'}}>
          <li>
            Ogeechee Technical College{" "}
            <a href="http://www.ogeecheetech.edu" target="_blank">
              www.ogeecheetech.edu
            </a>
          </li>
          <li>
            Georgia Southern University{" "}
            <a href="http://www.georgiasouthern.edu" target="_blank">
              www.georgiasouthern.edu
            </a>
          </li>
          <li>
            East Georgia State College{" "}
            <a href="http://www.ega.edu" target="_blank">
              www.ega.edu
            </a>
          </li>
          <li>
            Southeast Technical College{" "}
            <a href="http://www.southeasterntech.edu" target="_blank">
              www.southeasterntech.edu
            </a>
          </li>
        </ul>
      </Card>
    </div>
  </CommunityLayout>
)

export default EducationPage
